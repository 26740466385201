jQuery(document).ready(function ($) {
    $('.some-feed-load-more').click(function(e) {
        e.preventDefault();
        var index_number = $(this).parent().prev('.more-content').data('index');
        $(this).parent().prev('.more-content').data('index', index_number + 4);
        for (i = 0; i < 4; i++) {
            var feednum = i + index_number;
            if (feednum >= Object.keys(some_feed).length) {
                $(this).replaceWith('<h3 style="text-align: center">'+feed_translations.no_more_posts+'</h3>');
                break;
            }
            var content = some_feed[feednum]['text'];
            if (content.length >= 200){
                content = content.substring(0,200) + '...';
            }
            var html = '<div class="feed-column">' +
                '<div class="feed-post" style="background-image: url(\''+some_feed[feednum]['original']['images']['standard_resolution']['url']+'\')">' +
                '<a class="linkbox" href="'+some_feed[feednum]['original_url']+'"></a>' +
                '<div class="info">' +
                '<div class="profile">' +
                '<img alt="Author image" src="'+some_feed[feednum]['author_image_url']+'">' +
                '<a href="'+some_feed[feednum]['author_url']+'">'+some_feed[feednum]['screen_name']+'</a>' +
                '</div>' +
                '<div class="post-content">' +
                '<p>'+content+'</p>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>';
            $(this).parent().prev('.more-content').append(html);
        }
    });
});
